<template>
    <div class="deckslide">

        <div class="deckslide__header">
            <div class="deckslide__header__left">
                <img @click="$router.go(-1)"  src="@/assets/ic_nav_back_white.svg" alt="">
            </div>
            <div class="deckslide__header__center">
                Übungsvorschau
            </div>
            <div class="deckslide__header__right">
            </div>
        </div>

        <!-- WARMUP -->
        <div class="list__title list__title--white" v-if="warmupExercises.length !== 0">Warmup</div>
        <div class="list" v-for="(item, idx) in warmupExercises" :key="-idx">
            <PlaylistExercisesItem @showExercisePreview="showExercisePreview" :item="item"></PlaylistExercisesItem>
        </div>

        <!-- Workout -->
        <div class="list__title list__title--white" v-if="nonWarmupExercises.length !== 0">Workout</div>
        <div class="list" v-for="(item, idx) in nonWarmupExercises" :key="idx+1">
            <PlaylistExercisesItem @showExercisePreview="showExercisePreview" :item="item"></PlaylistExercisesItem>
        </div>

        <div class="deckslide__button">
            <div class="button button--primary" @click="startWorkout">Workout starten</div>
        </div>
        <ModalRoute v-if="showSubModal"/>
    </div>
</template>

<script>
import PlaylistExercisesItem from '@/components/PlaylistExercisesItem';
import ModalRoute from "@/components/ModalRoute.vue";

export default {
    name: 'PlaylistExercises',
    components: {
        ModalRoute,
        PlaylistExercisesItem
    },
    data() {
        return {
            warmupExercises: [],
            nonWarmupExercises: [],
            workoutSource: null,
            exercises: [],
            showSubModal: false,
            filteredWorkout: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        showExercisePreview(exercise) {
            this.showSubModal = true;
            this.$router.push("/playlist/exercises/preview/" + exercise.id);
        },
        async init() {
            this.workoutSource = this.$store.getters.getWorkoutSource;
            // exercises are necessary due to missing square_preview_image_url in old userPlayLists
            await this.$store.dispatch('fetchExercises').then(() => {
                this.exercises = this.$store.getters.getExercises;
            });
            this.filterMultipleExercises();
            if (this.workoutSource.warmupEnd) {
                this.warmupExercises = this.filteredWorkout.slice(0, this.workoutSource.warmupEnd);
            }
            this.nonWarmupExercises = this.filteredWorkout.slice(this.workoutSource.warmupEnd);
        },
        filterMultipleExercises() {
            let previousWorkoutId = null;
            let numberOfExecutions = 1;
            let previousWorkout = null;
            let itemWithSquarePrev = null
            this.workoutSource.generatedPlaylist.forEach(workout => {
                if (previousWorkoutId === null && previousWorkout === null) {
                    previousWorkout = workout;
                    previousWorkoutId = workout.id;
                }
                else if (previousWorkoutId === workout.id) {
                    numberOfExecutions++
                }
                else {
                    itemWithSquarePrev = this.exercises.find(el => el.id === previousWorkoutId);
                    if(previousWorkout.exerciseLength !== undefined) {
                        this.filteredWorkout.push({
                            numberOfExecutions: numberOfExecutions,
                            item: itemWithSquarePrev,
                            exerciseLength: previousWorkout.exerciseLength
                        });
                    }
                    else {
                        this.filteredWorkout.push({
                            numberOfExecutions: numberOfExecutions,
                            item: itemWithSquarePrev,
                            exerciseLength: this.workoutSource.exerciseLength
                        });
                    }
                    previousWorkout = workout;
                    previousWorkoutId = workout.id;
                    numberOfExecutions = 1;
                }
            });
                itemWithSquarePrev = this.exercises.find(el => el.id === previousWorkoutId);
                if (previousWorkout.exerciseLength !== undefined) {
                    this.filteredWorkout.push({
                        numberOfExecutions: numberOfExecutions,
                        item: itemWithSquarePrev,
                        exerciseLength: previousWorkout.exerciseLength
                    });
                } else {
                    this.filteredWorkout.push({
                        numberOfExecutions: numberOfExecutions,
                        item: itemWithSquarePrev,
                        exerciseLength: this.workoutSource.exerciseLength
                    });
                }
        },
        startWorkout() {
            this.$router.push("/playlist/countdown/");
        },
    },
    watch: {
        $route: {
            /* eslint-disable no-unused-vars */
            handler: function(newVal, oldVal) {
                if (newVal && newVal.name === "PlaylistExercises") {
                    this.showSubModal = false;
                }
            }
        }
    },
}
</script>

<style lang="scss">
</style>