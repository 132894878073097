<template>
    <div class="list__item list__item--background-light list__item--truncate" v-if="item" @click="previewExercise(item.item)">
        <div class="list__item__icon list__item__icon--image">
            <img :src="'https://app.sportlerplus.com' + item.item.square_preview_image_url" alt="PreviewImage">
        </div>
        <div class="list__item__content">
            <div class="list__item__text">
                <div class="list__item__toplabel">
                    {{ item.exerciseLength }} Sek.<span v-if="item.numberOfExecutions > 1"> ({{ item.numberOfExecutions }}x)</span>
                </div>
                <div class="list__item__label">
                    <span>{{ item.item.title }}</span>
                    <img v-if="item.item.requires_equipment" src="@/assets/ic_equipment-white.svg" class="equipment-icon" alt="EquipmentIcon">
                </div>
            </div>
            <div class="list__item__value">
                <img src="@/assets/ic_24_play_white.svg" alt="PlayButtonIcon">
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'PlaylistExercisesItem',
    data() {
        return {
        }
    },
    props: {
        item: {}
    },
    methods: {
        previewExercise(exercise) {
            this.$emit("showExercisePreview", exercise);
        },
    },
}
</script>
<style lang="scss" scoped>

    .list__item__label {
        display: flex;
        padding: 10px 0;
        margin: -10px 0;

        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .list__item__text {
        padding: 4px 0;
        margin: -4px 0;
        overflow: hidden;
    }
</style>